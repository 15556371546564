import { JsonObject, JsonProperty } from 'json2typescript';

import { ProviderRegion } from 'admin-sdk/api/common/App';

@JsonObject('NearestAppRegion')
export class NearestAppRegion {
  @JsonProperty('nearest_app_region')
  public nearestAppRegion: ProviderRegion = null!;

  constructor(required?: Required<NearestAppRegion>) {
    Object.assign(this, required);
  }
}

@JsonObject('ServiceSupportedRegions')
export class ServiceSupportedRegions {
  @JsonProperty('label')
  public label = '';

  @JsonProperty('value')
  public value = '';

  constructor(required?: Required<ServiceSupportedRegions>) {
    Object.assign(this, required);
  }
}
