import { EJSON } from 'bson';
import { JsonConvert } from 'json2typescript';
import queryString from 'query-string';

import { AppSettings, FeatureFlagStatus, FeatureSettings } from 'admin-sdk/api/v1/AppSettings';
import { AtlasCluster, CreateAtlasClusterRequest, CreateAtlasClusterResponse } from 'admin-sdk/api/v1/AtlasCluster';
import { AtlasDataLake, AtlasProduct } from 'admin-sdk/api/v1/AtlasDataLake';
import { AtlasGroup } from 'admin-sdk/api/v1/AtlasGroup';
import { ClusterStorageMetrics } from 'admin-sdk/api/v1/ClusterStorageMetrics';
import { NearestAppRegion, ServiceSupportedRegions } from 'admin-sdk/api/v1/Region';
import { CreateTempAPIKeyRequest, CreateTempAPIKeyResponse } from 'admin-sdk/api/v1/TempAPIKey';
import { APIKey, PartialAPIKey } from 'admin-sdk/api/v3/APIKey';
import { AppResourceNames, CreateAppRequest, PartialApp, UpdateEnvironmentRequest } from 'admin-sdk/api/v3/App';
import { CreateAtlasAPIKeyRequest, CreateAtlasAPIKeyResponse } from 'admin-sdk/api/v3/AtlasAPIKey';
import { AuthProviderConfig, AuthProviderType, PartialAuthProviderConfig } from 'admin-sdk/api/v3/AuthProvider';
import {
  CodeDeployUpdateRequest,
  EnableTTLInstallationAutoDeployRequest,
  GitHubBranch,
  GithubGroupAuthenticationResponse,
  GithubPushAppRequest,
  Installation,
  PartialCodeDeploy,
} from 'admin-sdk/api/v3/CodeDeploy';
import { DataAPIConfig } from 'admin-sdk/api/v3/DataAPI';
import {
  DebugExecuteFunctionRequest,
  DebugExecuteFunctionResponse,
  DebugExecuteFunctionSourceRequest,
} from 'admin-sdk/api/v3/DebugExecuteFunction';
import { AppDependencies, DependencyInstallation } from 'admin-sdk/api/v3/Dependencies';
import { DeployDraftPayload, Deployment, DeploymentsFilter } from 'admin-sdk/api/v3/Deployment';
import { DeploymentMigrationStatus, PutDeploymentMigrationRequest } from 'admin-sdk/api/v3/DeploymentMigration';
import { DraftDiff, PartialDraft } from 'admin-sdk/api/v3/Draft';
import {
  CreateEdgeServerRequest,
  DetailedEdgeServerInfo,
  EdgeRegistrationKeyResponse,
  EdgeServerInfos,
  RenameEdgeServerRequest,
} from 'admin-sdk/api/v3/Edge';
import { ConvertEndpointRequest, Endpoint } from 'admin-sdk/api/v3/Endpoint';
import { EnvironmentValue, PartialEnvironmentValue } from 'admin-sdk/api/v3/EnvironmentValue';
import {
  BaseEventSubscription,
  EventSubscriptionExecution,
  EventSubscriptionResumeOptions,
  ResourceType,
} from 'admin-sdk/api/v3/EventSubscription';
import { AppFunction, ClientAppFunction, CreateAppFunction, PartialAppFunction } from 'admin-sdk/api/v3/Function';
import { CustomResolver, deserializeGraphQLAlerts, ExtendableTypes, GraphQLConfig } from 'admin-sdk/api/v3/GraphQL';
import { AssetMetadata, AssetURL, HostingConfig, TransformAssetRequest } from 'admin-sdk/api/v3/Hosting';
import { IncomingWebhook, PartialIncomingWebhook } from 'admin-sdk/api/v3/IncomingWebhook';
import { AccessList, AllowedIPToStore, ListedAllowedIP } from 'admin-sdk/api/v3/IPAccess';
import { AppLogRequest, AppLogResponse, getLogFilter } from 'admin-sdk/api/v3/Log';
import { CreateLogForwarderRequest, LogForwarder, PatchLogForwarderRequest } from 'admin-sdk/api/v3/LogForwarder';
import {
  AppMeasurementGroup,
  getMeasurementFilter,
  GroupMeasurementGroup,
  MeasurementRequest,
} from 'admin-sdk/api/v3/Measurement';
import { AppMetrics, getMetricsFilter, GroupMetrics, MetricsRequestOptions } from 'admin-sdk/api/v3/Metric';
import { AllowNonVPCClientRequests, ProviderPrivateEndpointConfig } from 'admin-sdk/api/v3/ProviderPrivateEndpoint';
import { ProviderPrivateEndpointServiceInfo } from 'admin-sdk/api/v3/ProviderPrivateEndpointServiceInfo';
import { NearestProviderRegion, ProviderRegionData } from 'admin-sdk/api/v3/ProviderRegionData';
import { MessageState, PushNotification, SendNotificationRequest } from 'admin-sdk/api/v3/Push';
import {
  deserializePartialRule,
  deserializeRule,
  MongoDBBaseRule,
  MongoDBNamespace,
  MongoDBSyncIncompatibleRoles,
  PresetRole,
  Rule,
  serializeRule,
} from 'admin-sdk/api/v3/Rule';
import { GenerateSchemasRequest, GetSyncSchemasErrorResponse, PartialSchema, Schema } from 'admin-sdk/api/v3/Schema';
import { PartialSecret, Secret } from 'admin-sdk/api/v3/Secret';
import { PartialServiceDesc, ServiceDesc, ServiceDescConfig } from 'admin-sdk/api/v3/Service';
import { Snippet } from 'admin-sdk/api/v3/Snippets';
import {
  AllowedAsymmetricTables,
  AllowedQueryableFields,
  BypassServiceChangeValue,
  GetSchemaVersionsResponse,
  GetSyncAlertsResponse,
  GetSyncStateResponse,
  PatchSyncSchemasRequest,
  PutSyncMigrationRequest,
  SyncClientSchema,
  SyncConfig,
  SyncData,
  SyncMigrationPrecheck,
  SyncMigrationStatus,
  SyncProgress,
} from 'admin-sdk/api/v3/Sync';
import { Template } from 'admin-sdk/api/v3/Template';
import {
  Device,
  EmailPasswordRegistrationRequest,
  PartialUser,
  PasswordRecord,
  User,
  UserActionToken,
  UserFilter,
  UserProfile,
} from 'admin-sdk/api/v3/User';
import { CustomUserDataConfig, RefreshTokenExpirationPayload } from 'admin-sdk/api/v3/UserSettings';
import { NullTypeSchemaValidationSetting, ValidationOptions } from 'admin-sdk/api/v3/ValidationOptions';
import { PartialValue, Value } from 'admin-sdk/api/v3/Value';
import Auth from 'admin-sdk/auth';
import { Storage } from 'admin-sdk/auth/Storage';
import { DEFAULT_BAAS_SERVER_URL, FetchOptions, JSONTYPE, makeFetchArgs } from 'admin-sdk/Common';
import {
  ErrInvalidSession,
  ErrUIIPRestricted,
  ErrUnauthorized,
  isRawErrorResponse,
  ResponseError,
  ServerError,
  toErrorResponse,
} from 'admin-sdk/Error';

import { hasCloudInUrl } from './urls';

declare global {
  interface Window {
    settings: any;
    Cypress: any;
  }
}

const jsonConvert: JsonConvert = new JsonConvert();
const v1 = 1;
const v3 = 3;
const API_TYPE_PRIVATE = 'private';
const API_TYPE_ADMIN = 'admin';

export default class BaasAdminClient {
  get _private() {
    return this.apiMethods(API_TYPE_PRIVATE, v1);
  }

  get _admin() {
    return this.apiMethods(API_TYPE_ADMIN, v3);
  }

  public readonly auth: Auth;

  private readonly authUrl: string;

  private readonly rootURLsByAPIVersion: Record<number, Record<string, string>>;

  constructor(
    baseUrl: string = DEFAULT_BAAS_SERVER_URL,
    options: {
      requestOrigin?: string;
      storage?: Storage;
    } = {}
  ) {
    this.authUrl = `${baseUrl}/api/admin/v3.0/auth`;

    this.rootURLsByAPIVersion = {
      [v1]: {
        [API_TYPE_PRIVATE]: `${baseUrl}/api/private/v1.0`,
      },
      [v3]: {
        [API_TYPE_ADMIN]: `${baseUrl}/api/admin/v3.0`,
      },
    };

    const authOptions: { storage?: Storage; requestOrigin?: string } = {
      storage: options.storage,
    };

    if (options.requestOrigin) {
      authOptions.requestOrigin = options.requestOrigin;
    }

    this.auth = new Auth(this, this.authUrl, authOptions);
    this.auth.handleCookie();
  }

  /**
   * Submits an authentication request to the specified provider providing any
   * included options (read: user data).  If auth data already exists and the
   * existing auth data has an access token, then these credentials are returned.
   *
   * @param {String} providerType the provider used for authentication (The possible
   *                 options are 'userpass', 'apiKey', and 'mongodbCloud')
   * @param {Object} [options = {}] additional authentication options
   * @returns {Promise} which resolves to a String value: the authenticated user ID
   */
  public authenticate(providerType: AuthProviderType, options = {}) {
    // reuse existing auth if present
    const authenticateFn = () =>
      this.auth
        .provider(providerType)
        .authenticate(options)
        .then(() => this.authedId());

    if (this.isAuthenticated()) {
      return this.logout().then(() => authenticateFn()); // will not be authenticated, continue log in
    }

    // is not authenticated, continue log in
    return authenticateFn();
  }

  /**
   * Ends the session for the current user, and clears auth information from storage.
   *
   * @returns {Promise}
   */
  public logout() {
    return this._do('/auth/session', 'DELETE', {
      refreshOnFailure: false,
      useRefreshToken: true,
      requiresLegacyAuth: true,
    }).then(
      () => this.auth.clear(),
      () => this.auth.clear()
    );
  }

  /**
   * @returns {*} Returns any error from the server authentication system.
   */
  public authError() {
    return this.auth.error;
  }

  /**
   * @returns {Boolean} whether or not the current client is authenticated.
   */
  public isAuthenticated() {
    return !!this.authedId();
  }

  /**
   *  @returns {String} a string of the currently authenticated user's ID.
   */
  public authedId() {
    return this.auth.authedId;
  }

  /**
   * Returns an access token for the user
   *
   * @private
   * @returns {Promise}
   */
  public doSessionPost() {
    return this._do('/auth/session', 'POST', {
      refreshOnFailure: false,
      useRefreshToken: true,
      requiresLegacyAuth: true,
    }).then((response) => response.json());
  }

  public _fetch(
    url: string,
    fetchArgs: RequestInit,
    resource: string,
    method: string,
    options: FetchOptions = {}
  ): Promise<Response> {
    return fetch(url, fetchArgs).then((response: Response) => {
      // Okay: passthrough
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      }

      if (response.headers.get('Content-Type') === JSONTYPE) {
        return response.json().then((json) => {
          if (!isRawErrorResponse(json)) {
            return Promise.reject(json);
          }

          const err = toErrorResponse(json);

          // Only want to try refreshing token when there's an invalid baas session
          if (err.errorCode === ErrInvalidSession) {
            if (!options.refreshOnFailure) {
              this.auth.clear();
              const serverErr = new ServerError(err.error, err.errorCode);
              serverErr.response = response;
              serverErr.json = err;
              return Promise.reject(serverErr);
            }

            return this.auth.refreshToken().then(() => {
              options.refreshOnFailure = false;
              return this._do(resource, method, options);
            });
          }

          if (err.errorCode === ErrUIIPRestricted) {
            const serverErr = new ServerError(err.error, err.errorCode);
            serverErr.response = response;
            serverErr.json = err;
            return Promise.reject(serverErr);
          }

          const error = new ServerError(err.error, err.errorCode);
          error.response = response;
          error.json = err;
          return Promise.reject(error);
        });
      }
      const respErr = new ResponseError(response.statusText);
      respErr.response = response;
      return Promise.reject(respErr);
    });
  }

  public _fetchArgs(resource: string, method: string, options: FetchOptions) {
    const appURL = this.rootURLsByAPIVersion[options.apiVersion ?? v3][options.apiType ?? API_TYPE_ADMIN];
    let url = options.rootURL ? `${options.rootURL}${resource}` : `${appURL}${resource}`;
    const fetchArgs = makeFetchArgs(method, options);

    if (options.queryParams) {
      url = `${url}?${queryString.stringify(options.queryParams)}`;
    }

    return { url, fetchArgs };
  }

  public _do(resource: string, method: string, options: FetchOptions = {}) {
    options = {
      apiType: API_TYPE_ADMIN,
      apiVersion: v3,
      refreshOnFailure: true,
      rootURL: undefined,
      useRefreshToken: false,
      requiresLegacyAuth: false,
      ...options,
    };

    if (!options.headers) {
      options.headers = {};
    }
    if (this.auth.requestOrigin && !options.skipDraft) {
      options.headers['X-BAAS-Request-Origin'] = this.auth.requestOrigin;
    }

    const { url, fetchArgs } = this._fetchArgs(resource, method, options);

    if (options.noAuth) {
      return this._fetch(url, fetchArgs, resource, method, options);
    }

    const isBrowserRequest = typeof window !== 'undefined' || typeof document !== 'undefined';
    // TODO(BAAS-31697) remove window settings check once rolled out
    const isBrowserAuthNEnabled =
      this.auth.storage.store.getItem('_baas.browser_authn_enabled') === 'true' ||
      window.settings?.isBrowserAuthNEnabled;

    // cypress tests should use legacy auth
    const isCypressEnabled = window.Cypress;

    if (isBrowserRequest && hasCloudInUrl(url) && isBrowserAuthNEnabled && !isCypressEnabled) {
      // Use AuthN
      options.headers['X-AuthN-Tokens-Enabled'] = 'true';

      if (options.requiresLegacyAuth) {
        // TODO(BAAS-33290): Once AuthN starts supporting PUA, we will no longer need to manage a legacy session at all,
        // and we can remove the requiresLegacyAuth logic.
        // Currently, two types of requests could require legacy auth:

        // 1) Requests that use Programmatic User Access (PUA) and need a legacy access token, which contains
        // temporary programmatic api key (TPAK) credentials in the jwt payload.
        // These requests also require a cloud access token for authentication.

        // 2) Requests that need a legacy refresh token (options.useRefreshToken === true) to refresh a legacy session.
        return this._fetchWithLegacyAuth(url, fetchArgs, resource, method, options);
      }

      return this._fetch(url, fetchArgs, resource, method, options);
    }

    // When not using browser authN, always use legacy auth
    return this._fetchWithLegacyAuth(url, fetchArgs, resource, method, options);
  }

  private _fetchWithLegacyAuth(
    url: string,
    fetchArgs: RequestInit,
    resource: string,
    method: string,
    options: FetchOptions
  ) {
    const token = options.useRefreshToken ? this.auth.getRefreshToken() : this.auth.getAccessToken();

    if (!this.isAuthenticated()) {
      // Reject if request isn't authenticated via legacy auth
      return Promise.reject(new ServerError('Must auth first', ErrUnauthorized));
    }

    if (!options.headers) {
      options.headers = {};
    }
    options.headers.Authorization = `Bearer ${token}`;
    return this._fetch(url, fetchArgs, resource, method, options);
  }

  /**
   * Returns profile information for the currently logged in user
   *
   * @returns {Promise}
   */
  public userProfile() {
    return this._admin._get('/auth/profile', UserProfile);
  }

  /**
   * Returns available providers for the currently logged in admin
   *
   * @returns {Promise}
   */
  public getAuthProviders() {
    return this._do('/auth/providers', 'GET', {
      noAuth: true,
    })
      .then((response: Response) => response.text())
      .then((response) =>
        Object.values(EJSON.parse(response) as { [s: string]: any }).map((val: any) =>
          jsonConvert.deserializeObject(val, AuthProviderConfig)
        )
      );
  }

  /* Examples of how to access admin API with this client:
   *
   * List all apps
   *    a.apps('580e6d055b199c221fcb821c').list()
   *
   * Fetch app under name 'planner'
   *    a.apps('580e6d055b199c221fcb821c').app('planner').get()
   *
   * List services under the app 'planner'
   *    a.apps('580e6d055b199c221fcb821c').app('planner').services().list()
   *
   */
  public apps(groupId: string) {
    const api = this._admin;
    const groupUrl = `/groups/${groupId}/apps`;
    return {
      measurements: (request?: MeasurementRequest) => {
        const filter = getMeasurementFilter(request);
        return api._get(`/groups/${groupId}/measurements`, GroupMeasurementGroup, filter);
      },
      atlasAPIKeys: () => {
        return {
          create: (req: CreateAtlasAPIKeyRequest) =>
            api._post(
              `/groups/${groupId}/api_keys`,
              CreateAtlasAPIKeyResponse,
              CreateAtlasAPIKeyRequest,
              req,
              undefined,
              { requiresLegacyAuth: true }
            ),
        };
      },
      app: (appId: string) => {
        const appUrl = `${groupUrl}/${appId}`;
        return {
          get: () => api._get(appUrl, PartialApp),

          remove: () => api._delete(appUrl),

          pull: (deployment?: string) =>
            api._getUntyped(
              `${appUrl}/pull`,
              { deployment },
              {
                Accept: 'application/zip',
              }
            ),

          metrics: (request?: MetricsRequestOptions) => {
            const filter = getMetricsFilter(request);
            return api._get(`${appUrl}/metrics`, AppMetrics, filter);
          },

          measurements: (request?: MeasurementRequest) => {
            const filter = getMeasurementFilter(request);
            return api._get(`${appUrl}/measurements`, AppMeasurementGroup, filter);
          },

          commands: () => ({
            run: (command: string, data?: Record<string, any>) =>
              api._postUntyped(`${appUrl}/commands/${command}`, data, undefined, true),
          }),

          linkMultipleDataSources: (dataSources: ServiceDesc[]) =>
            api._postUntyped(`${appUrl}/multi_data_sources`, dataSources),

          dependencies: () => ({
            delete: (packageName: string) => api._delete(`${appUrl}/dependencies/${encodeURIComponent(packageName)}`),
            deleteAll: () => api._delete(`${appUrl}/dependencies`),
            list: () => api._get(`${appUrl}/dependencies`, AppDependencies),
            put: (filename: string, body: Blob) => {
              const form = new FormData();
              form.append('file', body, filename);
              return api._putRaw(`${appUrl}/dependencies`, {
                body: form,
                multipart: true,
              });
            },
            status: () => api._get(`${appUrl}/dependencies/status`, DependencyInstallation),
            upload: (filename: string, body: string) => {
              const form = new FormData();
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              form.append('file', body, filename);
              return api._postRaw(`${appUrl}/dependencies`, {
                body: form,
                multipart: true,
              });
            },
            upsert: (packageName: string, packageVersion?: string) => {
              return api._putRaw(`${appUrl}/dependencies/${encodeURIComponent(packageName)}`, {
                queryParams: {
                  version: packageVersion,
                },
              });
            },
          }),

          environment: () => ({
            update: (environment: UpdateEnvironmentRequest) =>
              api._put(`${appUrl}/environment`, UpdateEnvironmentRequest, environment),
          }),

          resourceNames: () => {
            return api._get(`${appUrl}/resource_names`, AppResourceNames);
          },

          environmentValues: () => ({
            create: (environmentValue: Omit<EnvironmentValue, 'id'>) =>
              api._post(`${appUrl}/environment_values`, EnvironmentValue, EnvironmentValue, environmentValue),
            list: () => api._list(`${appUrl}/environment_values`, PartialEnvironmentValue),
            environmentValue: (environmentValueId: string) => {
              const environmentValueUrl = `${appUrl}/environment_values/${environmentValueId}`;
              return {
                get: () => api._get(environmentValueUrl, EnvironmentValue),
                remove: () => api._delete(environmentValueUrl),
                update: (environmentValue: EnvironmentValue) =>
                  api._put(environmentValueUrl, EnvironmentValue, environmentValue),
              };
            },
          }),
          templates: () => ({
            get: () => api._list(`${appUrl}/templates`, Template),
            template: (templateId: string) => {
              const templateUrl = `${appUrl}/templates/${templateId}`;
              return {
                client: () => api._getUntyped(`${templateUrl}/client`),
              };
            },
          }),
          values: () => ({
            create: (val: Omit<Value, 'id'>) => api._post(`${appUrl}/values`, PartialValue, Value, val),
            list: () => api._list(`${appUrl}/values`, PartialValue),
            value: (valueId: string) => {
              const valueUrl = `${appUrl}/values/${valueId}`;
              return {
                get: () => api._get(valueUrl, Value),
                remove: () => api._delete(valueUrl),
                update: (val: Value) => api._put(valueUrl, Value, val),
              };
            },
          }),

          secrets: () => ({
            create: (secret: Omit<Secret, 'id'>) => api._post(`${appUrl}/secrets`, PartialSecret, Secret, secret),
            list: () => api._list(`${appUrl}/secrets`, PartialSecret),
            secret: (secretId: string) => {
              const secretUrl = `${appUrl}/secrets/${secretId}`;
              return {
                remove: () => api._delete(secretUrl),
                update: (secret: Secret) => api._put(secretUrl, Secret, secret),
              };
            },
          }),

          hosting: () => ({
            assets: () => ({
              asset: (path: string) => ({
                delete: () => api._delete(`${appUrl}/hosting/assets/asset`, { path }),
                get: () =>
                  api._get(`${appUrl}/hosting/assets/asset`, AssetMetadata, {
                    path,
                  }),
                patch: (options: { attributes: Array<{ name: string; value: string }> }) =>
                  api._patchRaw(`${appUrl}/hosting/assets/asset`, {
                    body: JSON.stringify({ attributes: options.attributes }),
                    queryParams: { path },
                  }),
              }),
              createDirectory: (folderName: string) =>
                api._putRaw(`${appUrl}/hosting/assets/asset`, {
                  body: JSON.stringify({ path: `${folderName}/` }),
                }),
              list: (prefix?: string, recursive?: boolean) => {
                const filter: Record<string, any> = {};
                if (prefix) {
                  filter.prefix = prefix;
                }
                if (recursive) {
                  filter.recursive = recursive;
                }
                return api._list(`${appUrl}/hosting/assets`, AssetMetadata, filter);
              },
              transform: (request: TransformAssetRequest) =>
                api._postNoContent(`${appUrl}/hosting/assets`, TransformAssetRequest, request),
              upload: (metadata: AssetMetadata, body: string) => {
                const form = new FormData();
                form.append('meta', JSON.stringify(jsonConvert.serialize(metadata, AssetMetadata)));
                form.append('file', body);
                return api._putRaw(`${appUrl}/hosting/assets/asset`, {
                  body: form,
                  multipart: true,
                });
              },
            }),
            cache: () => ({
              invalidate: (path: string) =>
                api._putRaw(`${appUrl}/hosting/cache`, {
                  body: JSON.stringify({ invalidate: true, path }),
                }),
            }),
            config: () => ({
              get: () => api._get(`${appUrl}/hosting/config`, HostingConfig),
              patch: (config: HostingConfig) => api._patch(`${appUrl}/hosting/config`, HostingConfig, config),
            }),
            presign: () => ({
              get: (path: string) =>
                api._get(`${appUrl}/hosting/presign`, AssetURL, {
                  path,
                }),
            }),
          }),

          deploy: () => ({
            auth: () => ({
              github: () =>
                api._getUntyped(`${appUrl}/deploy/github/auth`, undefined, undefined, {
                  credentials: 'include',
                }),
            }),
            config: () => api._get(`${appUrl}/deploy/config`, PartialCodeDeploy),
            deployments: () => ({
              get: (commit: string) => api._get(`${appUrl}/deployments/${commit}`, Deployment),
              list: (filter?: DeploymentsFilter) => {
                let queryFilter: Record<string, any>;
                if (filter) {
                  queryFilter = {};
                  if (filter.before) {
                    queryFilter.before = filter.before;
                  }
                  if (filter.limit) {
                    queryFilter.limit = filter.limit;
                  }
                  if (filter.draftId) {
                    queryFilter.draft_id = filter.draftId;
                  }
                  if (filter.userId) {
                    queryFilter.user_id = filter.userId;
                  }
                }
                return api._list(`${appUrl}/deployments`, Deployment, filter);
              },
              deployment: (deploymentId: string) => ({
                rename: (name: string) =>
                  api._patchRaw(`${appUrl}/deployments/${deploymentId}/rename`, {
                    body: JSON.stringify({ name }),
                  }),
                redeploy: () => api._postUntyped(`${appUrl}/deployments/${deploymentId}/redeploy`),
              }),
            }),
            installation: () => api._list(`${appUrl}/deploy/installation`, Installation),
            overwriteConfig: (config: CodeDeployUpdateRequest) =>
              api._put(`${appUrl}/deploy/config`, CodeDeployUpdateRequest, config),
            repositories: () => ({
              repository: (repoId: string) => ({
                github: () => ({
                  branches: () => ({
                    list: () => api._list(`${appUrl}/deploy/github/repositories/${repoId}/branches`, GitHubBranch),
                  }),
                }),
              }),
            }),
            updateConfig: (config: PartialCodeDeploy) =>
              api._patch(`${appUrl}/deploy/config`, PartialCodeDeploy, config),
            push: () => ({
              github: (request: GithubPushAppRequest, bypassServiceChange?: BypassServiceChangeValue) => {
                let queryParams: Record<string, any> | undefined;
                if (bypassServiceChange) {
                  queryParams = { bypass_service_change: bypassServiceChange };
                }
                return api._postNoContent(`${appUrl}/deploy/github/push`, GithubPushAppRequest, request, queryParams);
              },
            }),
            ttlInstallations: (installationId: string) => ({
              enableAutoDeploy: (request: EnableTTLInstallationAutoDeployRequest) =>
                api._postNoContent(
                  `${appUrl}/deploy/github/ttl_installations/${installationId}/enable_auto_deploy`,
                  EnableTTLInstallationAutoDeployRequest,
                  request
                ),
            }),
          }),

          drafts: () => ({
            create: () => api._postNoData(`${appUrl}/drafts`, PartialDraft),
            delete: (draftId: string) => api._delete(`${appUrl}/drafts/${draftId}`),
            deploy: (draftId: string, bypassServiceChange?: BypassServiceChangeValue, deploymentName?: string) => {
              const filter: Record<string, any> = { require_bypass_additive_changes: true };
              if (bypassServiceChange) {
                filter.bypass_service_change = bypassServiceChange;
              }
              return api._post(
                `${appUrl}/drafts/${draftId}/deployment`,
                Deployment,
                DeployDraftPayload,
                { name: deploymentName },
                filter
              );
            },
            diff: (draftId: string) => api._get(`${appUrl}/drafts/${draftId}/diff`, DraftDiff),
            get: (draftId: string) => api._get(`${appUrl}/drafts/${draftId}`, PartialDraft),
            list: () => api._list(`${appUrl}/drafts`, PartialDraft),
          }),

          services: () => ({
            create: (desc: ServiceDesc, skipDraft = false) =>
              api._post(`${appUrl}/services`, PartialServiceDesc, ServiceDesc, desc, undefined, {
                skipDraft,
              }),
            list: () => api._list(`${appUrl}/services`, PartialServiceDesc),
            service: (serviceId: string) => ({
              documentFields: () => api._list(`${appUrl}/services/${serviceId}/document_fields`, String),
              config: () => ({
                get: (): Promise<Record<string, any>> => api._getUntyped(`${appUrl}/services/${serviceId}/config`),
                getWithSecrets: () =>
                  api._get(`${appUrl}/services/${serviceId}/config`, ServiceDescConfig, {
                    includeSecretConfig: true,
                  }),
                update: (config: Record<string, any>, bypassServiceChange?: BypassServiceChangeValue) => {
                  let filter: Record<string, any> | undefined;
                  if (bypassServiceChange) {
                    filter = { bypass_service_change: bypassServiceChange };
                  }
                  return api._patchRaw(`${appUrl}/services/${serviceId}/config`, {
                    body: JSON.stringify(config),
                    queryParams: filter,
                  });
                },
                updateWithSecrets: (config: ServiceDescConfig) =>
                  api._patch(`${appUrl}/services/${serviceId}/config`, ServiceDescConfig, config),
              }),
              get: () => api._get(`${appUrl}/services/${serviceId}`, PartialServiceDesc),
              incomingWebhooks: () => ({
                create: (webhook: IncomingWebhook) =>
                  api._post(
                    `${appUrl}/services/${serviceId}/incoming_webhooks`,
                    PartialIncomingWebhook,
                    IncomingWebhook,
                    webhook
                  ),
                incomingWebhook: (incomingWebhookId: string) => {
                  const webhookUrl = `${appUrl}/services/${serviceId}/incoming_webhooks/${incomingWebhookId}`;
                  return {
                    get: () => api._get(webhookUrl, IncomingWebhook),
                    remove: () => api._delete(webhookUrl),
                    update: (webhook: IncomingWebhook) => api._put(webhookUrl, IncomingWebhook, webhook),
                  };
                },
                list: () => api._list(`${appUrl}/services/${serviceId}/incoming_webhooks`, PartialIncomingWebhook),
              }),
              remove: (bypassServiceChange?: BypassServiceChangeValue) => {
                let filter: Record<string, any> | undefined;
                if (bypassServiceChange) {
                  filter = { bypass_service_change: bypassServiceChange };
                }
                return api._delete(`${appUrl}/services/${serviceId}`, filter);
              },
              defaultRule: () => {
                const defaultRuleUrl = `${appUrl}/services/${serviceId}/default_rule`;
                return {
                  get: () => api._get(defaultRuleUrl, MongoDBBaseRule),
                  create: (defaultRule: MongoDBBaseRule, bypassServiceChange?: BypassServiceChangeValue) => {
                    let filter: Record<string, any> | undefined;
                    if (bypassServiceChange) {
                      filter = { bypass_service_change: bypassServiceChange };
                    }
                    return api._post(defaultRuleUrl, MongoDBBaseRule, MongoDBBaseRule, defaultRule, filter);
                  },
                  remove: () => {
                    return api._delete(defaultRuleUrl);
                  },
                  update: (defaultRule: MongoDBBaseRule, bypassServiceChange?: BypassServiceChangeValue) => {
                    let filter: Record<string, any> | undefined;
                    if (bypassServiceChange) {
                      filter = { bypass_service_change: bypassServiceChange };
                    }
                    return api._put(defaultRuleUrl, MongoDBBaseRule, defaultRule, filter);
                  },
                  namespaces: () => {
                    return api._list(`${defaultRuleUrl}/namespaces`, MongoDBNamespace);
                  },
                };
              },
              rules: () => {
                const rulesUrl = `${appUrl}/services/${serviceId}/rules`;
                return {
                  create: (rule: Rule, bypassServiceChange?: BypassServiceChangeValue) => {
                    let filter: Record<string, any> | undefined;
                    if (bypassServiceChange) {
                      filter = { bypass_service_change: bypassServiceChange };
                    }
                    return api._postConvert(rulesUrl, rule, deserializePartialRule, serializeRule, filter);
                  },
                  list: () => api._listConvert(rulesUrl, deserializePartialRule),
                  remove: (databaseName?: string) => {
                    let filter: Record<string, any> | undefined;
                    if (databaseName) {
                      filter = { database: databaseName };
                    }
                    return api._delete(rulesUrl, filter);
                  },
                  rule: (ruleId: string) => {
                    const ruleUrl = `${rulesUrl}/${ruleId}`;
                    return {
                      get: () => api._getConvert(deserializeRule, ruleUrl),
                      remove: (bypassServiceChange?: BypassServiceChangeValue) => {
                        let filter: Record<string, any> | undefined;
                        if (bypassServiceChange) {
                          filter = { bypass_service_change: bypassServiceChange };
                        }
                        return api._delete(ruleUrl, filter);
                      },
                      update: (rule: Rule, bypassServiceChange?: BypassServiceChangeValue) => {
                        let filter: Record<string, any> | undefined;
                        if (bypassServiceChange) {
                          filter = { bypass_service_change: bypassServiceChange };
                        }
                        return api._putConvertNoContent(ruleUrl, rule, serializeRule, filter);
                      },
                    };
                  },
                  syncIncompatibleRoles: () => {
                    return api._get(`${rulesUrl}/sync_incompatible_roles`, MongoDBSyncIncompatibleRoles);
                  },
                };
              },
              runCommand: (commandName: string, data?: Record<string, any>) =>
                api._postUntyped(`${appUrl}/services/${serviceId}/commands/${commandName}`, data),
              update: (version: number) =>
                api
                  ._patchRaw(`${appUrl}/services/${serviceId}`, {
                    body: JSON.stringify({ version }),
                  })
                  .then((response) => response.text())
                  .then((response) => jsonConvert.deserializeObject(EJSON.parse(response), PartialServiceDesc)),
            }),
          }),

          endpoints: () => ({
            create: (endpoint: Endpoint) => api._post(`${appUrl}/endpoints`, Endpoint, Endpoint, endpoint),
            list: () => api._list(`${appUrl}/endpoints`, Endpoint),
            endpoint: (endpointId: string) => ({
              get: () => api._get(`${appUrl}/endpoints/${endpointId}`, Endpoint),
              remove: () => api._delete(`${appUrl}/endpoints/${endpointId}`),
              update: (endpoint: Endpoint) => api._put(`${appUrl}/endpoints/${endpointId}`, Endpoint, endpoint),
              duplicate: () => api._postNoData(`${appUrl}/endpoints/${endpointId}`, Endpoint),
            }),
            convert: (request?: ConvertEndpointRequest) => {
              let filter: Record<string, any> | undefined;
              if (request) {
                filter = {};
                if (request.strategy) {
                  filter.strategy = request.strategy;
                }
              }
              return api._put(`${appUrl}/endpoints/convert`, ConvertEndpointRequest, undefined, filter);
            },
          }),

          pushNotifications: () => ({
            create: (request: SendNotificationRequest) =>
              api._post(`${appUrl}/push/notifications`, PushNotification, SendNotificationRequest, request),
            list: (state: MessageState) =>
              api._list(`${appUrl}/push/notifications`, PushNotification, {
                state,
              }),
            pushNotification: (messageId: string) => ({
              get: () => api._get(`${appUrl}/push/notifications/${messageId}`, PushNotification),
              remove: () => api._delete(`${appUrl}/push/notifications/${messageId}`),
              send: () => api._postUntyped(`${appUrl}/push/notifications/${messageId}/send`),
              update: (request: SendNotificationRequest) =>
                api._put(`${appUrl}/push/notifications/${messageId}`, SendNotificationRequest, request),
            }),
          }),

          users: () => ({
            count: (): Promise<number> => api._getUntyped(`${appUrl}/users_count`).then((result) => result as number),
            create: (request: EmailPasswordRegistrationRequest) =>
              api._post(`${appUrl}/users`, PartialUser, EmailPasswordRegistrationRequest, request),
            list: (filter?: UserFilter) => {
              let queryFilter: Record<string, any>;
              if (filter) {
                queryFilter = {};
                if (filter.descending) {
                  queryFilter.desc = filter.descending;
                }
                if (filter.after) {
                  queryFilter.after = filter.after;
                }
                if (filter.providerTypes) {
                  queryFilter.provider_types = filter.providerTypes;
                }
                if (filter.sort) {
                  queryFilter.sort = filter.sort;
                }
                if (filter.limit) {
                  queryFilter.limit = filter.limit;
                }
              }
              return api._list(`${appUrl}/users`, PartialUser, filter);
            },
            user: (uid: string) => ({
              devices: () => ({
                get: () => api._list(`${appUrl}/users/${uid}/devices`, Device),
              }),
              disable: () => api._putRaw(`${appUrl}/users/${uid}/disable`),
              enable: () => api._putRaw(`${appUrl}/users/${uid}/enable`),
              get: () => api._get(`${appUrl}/users/${uid}`, User),
              logout: () => api._putRaw(`${appUrl}/users/${uid}/logout`),
              remove: () => api._delete(`${appUrl}/users/${uid}`),
            }),
          }),

          userRegistrations: () => ({
            confirmByEmail: (email: string) =>
              api._postUntyped(`${appUrl}/user_registrations/by_email/${email}/confirm`),
            listPending: (limit?: number, after?: string) => {
              let filter: Record<string, any> | undefined;
              if (limit || after) {
                filter = {};
                if (limit) {
                  filter.limit = limit;
                }
                if (after) {
                  filter.after = after;
                }
              }
              return api._list(`${appUrl}/user_registrations/pending_users`, PasswordRecord, filter);
            },
            removePendingUserByEmail: (email: string) => api._delete(`${appUrl}/user_registrations/by_email/${email}`),
            removePendingUserByID: (id: string) => api._delete(`${appUrl}/user_registrations/by_id/${id}`),
            runUserConfirmation: (email: string) =>
              api._postNoData(`${appUrl}/user_registrations/by_email/${email}/run_confirm`, UserActionToken),
            sendConfirmationEmail: (email: string) =>
              api._postNoData(`${appUrl}/user_registrations/by_email/${email}/send_confirm`, UserActionToken),
          }),

          customUserData: () => ({
            get: () => api._get(`${appUrl}/custom_user_data`, CustomUserDataConfig),
            update: (config: CustomUserDataConfig) =>
              api._patch(`${appUrl}/custom_user_data`, CustomUserDataConfig, config),
          }),

          debug: () => ({
            executeFunction: (userId: string, name = '', ...args: any[]) =>
              api._postTypedJSON(
                `${appUrl}/debug/execute_function`,
                DebugExecuteFunctionResponse,
                DebugExecuteFunctionRequest,
                { name, arguments: args },
                { user_id: userId }
              ),
            executeFunctionSource: ({
              userId,
              source = '',
              evalSource = '',
              runAsSystem,
            }: {
              userId?: string;
              source: string;
              evalSource: string;
              runAsSystem: boolean;
            }) =>
              api._postTypedJSON(
                `${appUrl}/debug/execute_function_source`,
                DebugExecuteFunctionResponse,
                DebugExecuteFunctionSourceRequest,
                { source, evalSource },
                { user_id: userId, run_as_system: runAsSystem }
              ),
          }),

          authProviders: () => ({
            authProvider: (providerId: string) => ({
              disable: () => api._putRaw(`${appUrl}/auth_providers/${providerId}/disable`),
              enable: () => api._putRaw(`${appUrl}/auth_providers/${providerId}/enable`),
              get: () => api._get(`${appUrl}/auth_providers/${providerId}`, AuthProviderConfig),
              getDeployed: () => api._get(`${appUrl}/auth_providers/${providerId}/deployed`, AuthProviderConfig),
              remove: () => api._delete(`${appUrl}/auth_providers/${providerId}`),
              update: (config: AuthProviderConfig) =>
                api._patch(`${appUrl}/auth_providers/${providerId}`, AuthProviderConfig, config),
            }),
            create: (config: AuthProviderConfig) =>
              api._post(`${appUrl}/auth_providers`, PartialAuthProviderConfig, AuthProviderConfig, config),
            list: () => api._list(`${appUrl}/auth_providers`, PartialAuthProviderConfig),
          }),

          security: () => ({
            allowedRequestOrigins: () => ({
              get: () => api._list(`${appUrl}/security/allowed_request_origins`, String),
              update: (origins: string[]) => api._postUntyped(`${appUrl}/security/allowed_request_origins`, origins),
            }),
            accessList: () => ({
              create: (allowedIP: AllowedIPToStore) =>
                api._post(`${appUrl}/security/access_list`, ListedAllowedIP, AllowedIPToStore, allowedIP),
              get: () => api._get(`${appUrl}/security/access_list`, AccessList),
              allowedIP: (ipID: string) => {
                const allowedIPUrl = `${appUrl}/security/access_list/${ipID}`;
                return {
                  update: (allowedIP: AllowedIPToStore) =>
                    api._patchTyped(allowedIPUrl, ListedAllowedIP, AllowedIPToStore, allowedIP),
                  delete: () => api._delete(allowedIPUrl),
                };
              },
            }),
            providerPrivateEndpoints: () => {
              const privateEndpointsUrl = `${appUrl}/security/private_endpoints`;
              return {
                list: () => api._list(privateEndpointsUrl, ProviderPrivateEndpointConfig),
                create: (ppeConfig: ProviderPrivateEndpointConfig) =>
                  api._post(
                    privateEndpointsUrl,
                    ProviderPrivateEndpointConfig,
                    ProviderPrivateEndpointConfig,
                    ppeConfig
                  ),
                providerPrivateEndpoint: (ppeConfigID: string) => {
                  const ppeUrl = `${appUrl}/security/private_endpoints/${ppeConfigID}`;
                  return {
                    delete: () => api._delete(ppeUrl),
                    get: () => api._get(ppeUrl, ProviderPrivateEndpointConfig),
                    update: (ppeConfig: ProviderPrivateEndpointConfig) =>
                      api._put(ppeUrl, ProviderPrivateEndpointConfig, ppeConfig),
                  };
                },
              };
            },
            allowNonVPCClientRequests: () => {
              const allowNonVPCClientRequestsUrl = `${appUrl}/security/allow_non_vpc_client_requests`;
              return {
                get: () => api._get(allowNonVPCClientRequestsUrl, AllowNonVPCClientRequests),
                update: (allowNonVPCClientRequests: AllowNonVPCClientRequests) =>
                  api._postNoContent(
                    allowNonVPCClientRequestsUrl,
                    AllowNonVPCClientRequests,
                    allowNonVPCClientRequests
                  ),
              };
            },
            refreshTokenExpiration: () => ({
              get: () => api._get(`${appUrl}/security/refresh_token_expiration`, RefreshTokenExpirationPayload),
              update: (expirationTime: RefreshTokenExpirationPayload) =>
                api._put(`${appUrl}/security/refresh_token_expiration`, RefreshTokenExpirationPayload, expirationTime),
            }),
          }),

          logs: () => ({
            list: (request?: AppLogRequest) => {
              const filter = getLogFilter(request);
              return api._get(`${appUrl}/logs`, AppLogResponse, filter);
            },

            download: (request?: AppLogRequest) => {
              const filter = getLogFilter(request);
              return api._getUntyped(`${appUrl}/logs/download`, filter, {
                Accept: 'application/zip',
              });
            },
          }),

          logForwarders: () => ({
            list: () => api._list(`${appUrl}/log_forwarders`, LogForwarder),
            create: (logForwarder: CreateLogForwarderRequest) =>
              api._post(`${appUrl}/log_forwarders`, LogForwarder, CreateLogForwarderRequest, logForwarder),
            logForwarder: (logForwarderId: string) => ({
              get: () => api._get(`${appUrl}/log_forwarders/${logForwarderId}`, LogForwarder),
              remove: () => api._delete(`${appUrl}/log_forwarders/${logForwarderId}`),
              enable: () => api._putRaw(`${appUrl}/log_forwarders/${logForwarderId}/enable`),
              disable: () => api._putRaw(`${appUrl}/log_forwarders/${logForwarderId}/disable`),
              update: (logForwarder: PatchLogForwarderRequest) =>
                api._patchTyped(
                  `${appUrl}/log_forwarders/${logForwarderId}`,
                  LogForwarder,
                  PatchLogForwarderRequest,
                  logForwarder
                ),
              resume: () => api._putRaw(`${appUrl}/log_forwarders/${logForwarderId}/resume`),
            }),
          }),

          apiKeys: () => ({
            apiKey: (apiKeyId: string) => ({
              disable: () => api._putRaw(`${appUrl}/api_keys/${apiKeyId}/disable`),
              enable: () => api._putRaw(`${appUrl}/api_keys/${apiKeyId}/enable`),
              get: () => api._get(`${appUrl}/api_keys/${apiKeyId}`, PartialAPIKey),
              remove: () => api._delete(`${appUrl}/api_keys/${apiKeyId}`),
            }),
            create: (key: Pick<APIKey, 'name'>) => api._post(`${appUrl}/api_keys`, APIKey, APIKey, key),
            list: () => api._list(`${appUrl}/api_keys`, PartialAPIKey),
          }),

          functions: () => ({
            create: (func: CreateAppFunction) =>
              api._post(`${appUrl}/functions`, PartialAppFunction, CreateAppFunction, func),
            function: (functionId: string) => ({
              get: () => api._get(`${appUrl}/functions/${functionId}`, ClientAppFunction),
              remove: () => api._delete(`${appUrl}/functions/${functionId}`),
              update: (func: AppFunction) => api._put(`${appUrl}/functions/${functionId}`, AppFunction, func),
            }),
            list: () => api._list(`${appUrl}/functions`, PartialAppFunction),
          }),

          eventSubscriptions: () => ({
            create: (sub: BaseEventSubscription) => {
              return api._post(`${appUrl}/event_subscriptions`, BaseEventSubscription, BaseEventSubscription, sub);
            },
            eventSubscription: (eventSubscriptionId: string) => ({
              get: () => api._get(`${appUrl}/event_subscriptions/${eventSubscriptionId}`, BaseEventSubscription),
              remove: () => api._delete(`${appUrl}/event_subscriptions/${eventSubscriptionId}`),
              resume: (options: EventSubscriptionResumeOptions) =>
                api._put(
                  `${appUrl}/event_subscriptions/${eventSubscriptionId}/resume`,
                  EventSubscriptionResumeOptions,
                  options
                ),
              update: (sub: BaseEventSubscription) =>
                api._put(`${appUrl}/event_subscriptions/${eventSubscriptionId}`, BaseEventSubscription, sub),
              execution: () =>
                api._get(`${appUrl}/event_subscriptions/${eventSubscriptionId}/execution`, EventSubscriptionExecution),
            }),
            list: (type?: ResourceType) => {
              let filter: Record<string, any> | undefined;
              if (type) {
                filter = { type };
              }
              return api._list(`${appUrl}/event_subscriptions`, BaseEventSubscription, filter);
            },
          }),

          validationSettings: () => {
            const validationSettingsUrl = `${appUrl}/validation_settings`;

            return {
              graphql: () => {
                const graphqlUrl = `${validationSettingsUrl}/graphql`;

                return {
                  get: () => api._get(graphqlUrl, ValidationOptions),
                  update: (settings: ValidationOptions) => api._put(graphqlUrl, ValidationOptions, settings),
                };
              },
              nullTypeSchemaValidation: () => {
                const nullTypeSchemaValUrl = `${validationSettingsUrl}/null_type_schema_validation`;

                return {
                  get: () => api._get(nullTypeSchemaValUrl, NullTypeSchemaValidationSetting),
                  update: (setting: NullTypeSchemaValidationSetting) =>
                    api._put(nullTypeSchemaValUrl, NullTypeSchemaValidationSetting, setting),
                };
              },
            };
          },

          graphql: () => {
            const graphqlUrl = `${appUrl}/graphql`;

            return {
              config: () => ({
                get: () => api._get(`${graphqlUrl}/config`, GraphQLConfig),
                update: (config: GraphQLConfig) => api._put(`${graphqlUrl}/config`, GraphQLConfig, config),
              }),
              customResolvers: () => ({
                create: (resolver: CustomResolver) =>
                  api._post(`${graphqlUrl}/custom_resolvers`, CustomResolver, CustomResolver, resolver),
                customResolver: (id: string) => ({
                  get: () => api._get(`${graphqlUrl}/custom_resolvers/${id}`, CustomResolver),
                  remove: () => api._delete(`${graphqlUrl}/custom_resolvers/${id}`),
                  update: (resolver: CustomResolver) =>
                    api._put(`${graphqlUrl}/custom_resolvers/${id}`, CustomResolver, resolver),
                }),
                list: () => api._list(`${graphqlUrl}/custom_resolvers`, CustomResolver),
              }),
              post: (data: Record<string, any>) => api._postUntyped(`${graphqlUrl}`, data),
              validate: () => api._listConvert(`${graphqlUrl}/validate`, deserializeGraphQLAlerts),
              extendableTypes: () => api._get(`${graphqlUrl}/extendable_types`, ExtendableTypes),
            };
          },

          sync: () => {
            const syncUrl = `${appUrl}/sync`;
            return {
              alerts: () => {
                const alertsUrl = `${syncUrl}/alerts`;
                return {
                  get: () => {
                    return api._get(alertsUrl, GetSyncAlertsResponse);
                  },
                };
              },
              clientSchemas: () => {
                const clientSchemasUrl = `${syncUrl}/client_schemas`;
                return {
                  get: (language: string, namespaces?: string[], schemaVersion?: number) => {
                    const filter: Record<string, any> | undefined = {};
                    if (namespaces && namespaces.length > 0) {
                      filter.namespace = namespaces;
                    }
                    if (schemaVersion || schemaVersion === 0) {
                      filter.schemaVersion = schemaVersion;
                    }
                    return api._list(`${clientSchemasUrl}/${language}`, SyncClientSchema, filter);
                  },
                };
              },
              config: () => {
                const configUrl = `${syncUrl}/config`;
                return {
                  get: () => api._get(configUrl, SyncConfig),
                  update: (config: SyncConfig) => api._put(configUrl, SyncConfig, config),
                };
              },
              data: (serviceId?: string) => {
                let filter: Record<string, any> | undefined;
                if (serviceId) {
                  filter = { service_id: serviceId };
                }
                return api._get(`${syncUrl}/data`, SyncData, filter);
              },
              allowedQueryableFields: (serviceId: string) => {
                const queryableFieldUrl = `${appUrl}/services/${serviceId}/sync/allowed_queryable_fields`;
                return api._getConvert<AllowedQueryableFields>((response: any) => {
                  return new AllowedQueryableFields(response);
                }, queryableFieldUrl);
              },
              allowedAsymmetricTables: (serviceId: string) => {
                const asymmetricTableUrl = `${appUrl}/services/${serviceId}/sync/allowed_asymmetric_tables`;
                return api._getConvert<AllowedAsymmetricTables>((response: any) => {
                  return new AllowedAsymmetricTables(response);
                }, asymmetricTableUrl);
              },
              progress: () => api._get(`${syncUrl}/progress`, SyncProgress),
              schemas: () => {
                return {
                  patch: (request: PatchSyncSchemasRequest) =>
                    api._patch(`${syncUrl}/schemas`, PatchSyncSchemasRequest, request),
                  versions: () => {
                    const versionsUrl = `${syncUrl}/schemas/versions`;
                    return {
                      get: () => api._get(versionsUrl, GetSchemaVersionsResponse),
                    };
                  },
                };
              },
              state: () => {
                return {
                  get: (syncType: string) => {
                    return api._get(`${syncUrl}/state`, GetSyncStateResponse, { sync_type: syncType });
                  },
                };
              },
              migration: () => {
                const migrationUrl = `${syncUrl}/migration`;
                return {
                  precheck: () => api._get(`${migrationUrl}/precheck`, SyncMigrationPrecheck),
                  status: () => api._get(migrationUrl, SyncMigrationStatus),
                  put: (req: PutSyncMigrationRequest) => api._put(migrationUrl, PutSyncMigrationRequest, req),
                };
              },
            };
          },

          edge: () => {
            const edgeUrl = `${appUrl}/edge`;
            return {
              get: () => api._get(edgeUrl, EdgeServerInfos),
              create: (req: CreateEdgeServerRequest) =>
                api._post(edgeUrl, EdgeRegistrationKeyResponse, CreateEdgeServerRequest, req),
              info: (edgeServerId: string) =>
                api._getConvert<DetailedEdgeServerInfo>(
                  (response: any) => new DetailedEdgeServerInfo(response),
                  `${edgeUrl}/${edgeServerId}`
                ),
              rename: (edgeServerId: string, req: RenameEdgeServerRequest) =>
                api._put(`${edgeUrl}/${edgeServerId}/rename`, RenameEdgeServerRequest, req),
              delete: (edgeServerId: string) => api._delete(`${edgeUrl}/${edgeServerId}`),
              regenerateApiKey: (edgeServerId: string) =>
                api._postNoData(`${edgeUrl}/${edgeServerId}/regenerate-registration-key`, EdgeRegistrationKeyResponse),
            };
          },

          schemas: () => ({
            create: (schema: Omit<Schema, 'id'>, bypassServiceChange?: BypassServiceChangeValue) => {
              const filter: Record<string, any> = { require_bypass_additive_changes: true };
              if (bypassServiceChange) {
                filter.bypass_service_change = bypassServiceChange;
              }
              return api._post(`${appUrl}/schemas`, Schema, Schema, schema, filter);
            },
            list: () => api._list(`${appUrl}/schemas`, PartialSchema),
            remove: (dataSource: string, database?: string) =>
              api._delete(`${appUrl}/schemas`, { data_source: dataSource, database }),
            schema: (schemaId: string) => {
              const schemaUrl = `${appUrl}/schemas/${schemaId}`;

              return {
                get: () => api._get(schemaUrl, Schema),
                remove: (bypassServiceChange?: BypassServiceChangeValue) => {
                  let filter: Record<string, any> | undefined;
                  if (bypassServiceChange) {
                    filter = { bypass_service_change: bypassServiceChange };
                  }
                  return api._delete(schemaUrl, filter);
                },
                update: (schema: Schema, bypassServiceChange?: BypassServiceChangeValue) => {
                  const filter: Record<string, any> = { require_bypass_additive_changes: true };
                  if (bypassServiceChange) {
                    filter.bypass_service_change = bypassServiceChange;
                  }
                  return api._put(schemaUrl, Schema, schema, filter);
                },
              };
            },
            bulkGenerateSchema: (request: GenerateSchemasRequest) =>
              api._postNoContent(`${appUrl}/generate/schemas`, GenerateSchemasRequest, request),
            getSyncErrors: () => api._get(`${appUrl}/sync_schema_errors`, GetSyncSchemasErrorResponse),
          }),

          dataAPI: () => ({
            config: () => {
              const dataAPIConfigUrl = `${appUrl}/data_api/config`;
              return {
                get: () => api._get(dataAPIConfigUrl, DataAPIConfig),
                create: (config: DataAPIConfig) => api._post(dataAPIConfigUrl, DataAPIConfig, DataAPIConfig, config),
                update: (config: DataAPIConfig) => api._patch(dataAPIConfigUrl, DataAPIConfig, config),
              };
            },
          }),

          deploymentMigration: () => {
            const deploymentMigrationUrl = `${appUrl}/deployment_migration`;
            return {
              put: (deploymentMigration: PutDeploymentMigrationRequest) =>
                api._put(deploymentMigrationUrl, PutDeploymentMigrationRequest, deploymentMigration),
              get: () => api._get(deploymentMigrationUrl, DeploymentMigrationStatus),
            };
          },
        };
      },
      deploy: () => {
        const groupDeployUrl = `/groups/${groupId}/deploy`;

        return {
          auth: () => ({
            github: () =>
              api._get(`${groupDeployUrl}/github/auth`, GithubGroupAuthenticationResponse, undefined, undefined, {
                credentials: 'include',
              }),
          }),
          installationRequest: (requestId: string) =>
            api._list(`${groupDeployUrl}/installation_request/${requestId}`, Installation),
          ttlInstallations: (installationId: string) => {
            const ttlInstallationUrl = `${groupDeployUrl}/github/ttl_installations/${installationId}`;

            return {
              repository: (repoId: string) => ({
                github: () => ({
                  branches: () => {
                    const branchesUrl = `${ttlInstallationUrl}/repositories/${repoId}/branches`;

                    return {
                      branch: (branchName: string) => ({
                        directories: () => ({
                          list: (githubAuthRequestId: string) =>
                            api._postUntyped(`${branchesUrl}/${branchName}`, {
                              github_auth_request_id: githubAuthRequestId,
                            }),
                        }),
                      }),
                      list: (githubAuthRequestId: string) =>
                        api._postUntyped(`${branchesUrl}`, { github_auth_request_id: githubAuthRequestId }),
                    };
                  },
                }),
              }),
            };
          },
        };
      },
      create: (request: CreateAppRequest) => {
        const query = request.product ? `?product=${request.product}` : '';
        return api._post(groupUrl + query, PartialApp, CreateAppRequest, request, undefined, {
          requiresLegacyAuth: true,
        });
      },

      list: (product?: string) => api._list(groupUrl, PartialApp, product ? { product } : undefined),
    };
  }

  public templates() {
    const api = this._admin;
    return {
      list: () => api._list('/templates', Template),
    };
  }

  public snippets() {
    const api = this._admin;
    return {
      list: () => api._list('/snippets', Snippet),
    };
  }

  public dataAPI() {
    const api = this._admin;
    return {
      versions: () => ({
        list: () => api._list(`/data_api/versions`, String),
      }),
    };
  }

  public rules() {
    const api = this._admin;
    const rulesUrl = '/rules';
    return {
      presetRoles: () => ({
        list: () => api._list(`${rulesUrl}/preset_roles`, PresetRole),
      }),
    };
  }

  public security() {
    const api = this._admin;
    const securityUrl = '/security';
    return {
      providerPrivateEndpointServiceInfo: () => ({
        list: () => api._list(`${securityUrl}/private_endpoint_service_infos`, ProviderPrivateEndpointServiceInfo),
      }),
    };
  }

  public providerRegions() {
    const api = this._admin;
    const providerRegionsUrl = '/provider_regions';
    return {
      list: () => api._list(providerRegionsUrl, ProviderRegionData),
      nearest: () => {
        return {
          get: () => api._get(`${providerRegionsUrl}/nearest`, NearestProviderRegion),
        };
      },
    };
  }

  public private() {
    const privateApi = this._private;
    return {
      defaultM0ClusterVersion: () => {
        const defaultM0ClusterVersionUrl = '/default_m0_version';
        return {
          get: () => privateApi._getUntyped(defaultM0ClusterVersionUrl),
        };
      },
      auth: () => ({
        tempAPIKeys: () => {
          const apiKeysUrl = '/auth/temp_api_keys';
          return {
            create: (request: CreateTempAPIKeyRequest) =>
              privateApi._post(apiKeysUrl, CreateTempAPIKeyResponse, CreateTempAPIKeyRequest, request, undefined, {
                requiresLegacyAuth: true,
              }),
          };
        },
      }),
      group: (groupId: string) => {
        const groupUrl = `/groups/${groupId}`;
        return {
          metrics: (request?: MetricsRequestOptions) => {
            const filter = getMetricsFilter(request);
            return privateApi._get(`${groupUrl}/metrics`, GroupMetrics, filter);
          },
          app: (appId: string) => {
            const appUrl = `${groupUrl}/apps/${appId}`;
            return {
              atlasClusters: () => {
                const clustersUrl = `${appUrl}/atlas_clusters`;
                return {
                  create: (regionName?: string) =>
                    privateApi._post(
                      clustersUrl,
                      CreateAtlasClusterResponse,
                      CreateAtlasClusterRequest,
                      { regionName },
                      undefined,
                      { credentials: 'include', requiresLegacyAuth: true }
                    ),
                };
              },
              settings: () => {
                return {
                  get: () => {
                    return privateApi._get(`${appUrl}/settings`, AppSettings);
                  },
                };
              },
              features: () => {
                return {
                  get: (status?: FeatureFlagStatus) => {
                    return privateApi._get(`${appUrl}/features${status ? `?status=${status}` : ''}`, FeatureSettings);
                  },
                };
              },
            };
          },
          atlasClusters: () => {
            const clustersUrl = `${groupUrl}/atlas_clusters`;
            return {
              list: () =>
                privateApi._list(clustersUrl, AtlasCluster, undefined, undefined, {
                  requiresLegacyAuth: true,
                }),
              cluster: (clusterName: string) => {
                const clusterUrl = `${clustersUrl}/${clusterName}`;
                return {
                  get: (fields?: string[]) => {
                    const query = fields ? `?fields=${fields.join(',')}` : '';
                    return privateApi._get(clusterUrl + query, AtlasCluster);
                  },
                  storageMetrics: () => {
                    const clusterStorageMetricsUrl = `${clusterUrl}/storage_metrics`;
                    return {
                      get: () =>
                        privateApi._get(clusterStorageMetricsUrl, ClusterStorageMetrics, undefined, undefined, {
                          requiresLegacyAuth: true,
                        }),
                    };
                  },
                };
              },
            };
          },
          atlasDataLakes: (atlasProduct?: AtlasProduct) => {
            const dataLakesUrl = `${groupUrl}/atlas_datalakes${atlasProduct ? `?atlas_product=${atlasProduct}` : ''}`;
            return {
              list: () => privateApi._list(dataLakesUrl, AtlasDataLake),
            };
          },
          atlasServerlessInstances: () => {
            const serverlessInstancesUrl = `${groupUrl}/atlas_serverless_instances`;
            return {
              list: () =>
                privateApi._list(serverlessInstancesUrl, AtlasCluster, undefined, undefined, {
                  requiresLegacyAuth: true,
                }),
            };
          },
          get: () => privateApi._get(groupUrl, AtlasGroup, undefined, undefined, { requiresLegacyAuth: true }),
        };
      },
      spa: () => ({
        recaptcha: () => ({
          verify: (token: string) =>
            privateApi._postRaw(`/spa/recaptcha/verify`, {
              credentials: 'include',
              multipart: true,
              noAuth: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              body: new URLSearchParams(`response=${token}`),
            }),
        }),
        settings: () => ({
          global: (auid?: string) => privateApi._getUntyped(`/spa/settings/global`, auid ? { auid } : undefined),
        }),
      }),
      provider: (provider: string) => {
        const providerUrl = `/provider/${provider}`;
        return {
          atlasRegions: (atlasRegion: string) => {
            const atlasRegions = `${providerUrl}/atlas_regions/${atlasRegion}`;
            return {
              nearestAppRegion: () => {
                const nearestAppRegion = `${atlasRegions}/nearest_app_region`;
                return {
                  get: () => privateApi._get(nearestAppRegion, NearestAppRegion),
                };
              },
            };
          },
          service: (service: string) => {
            const serviceUrl = `${providerUrl}/service/${service}`;
            return {
              regions: () => {
                const regionsUrl = `${serviceUrl}/regions`;
                return {
                  get: () => privateApi._list(regionsUrl, ServiceSupportedRegions),
                };
              },
            };
          },
        };
      },
    };
  }

  private apiMethods(apiType: string, apiVersion: number) {
    const doRequest = (url: string, method: string, options?: FetchOptions) =>
      this._do(url, method, { apiType, apiVersion, ...options });

    const doJSON = (url: string, method: string, options?: FetchOptions) =>
      doRequest(url, method, options).then((response) => {
        const contentHeader = response.headers.get('content-type') || '';
        if (contentHeader.split(',').indexOf('application/json') >= 0) {
          return response.json();
        }
        return response;
      });

    const doText = (url: string, method: string, options?: FetchOptions) =>
      doRequest(url, method, options).then((response) => response.text());

    const doNoContent = (url: string, method: string, options?: FetchOptions): Promise<void> =>
      doRequest(url, method, options).then(() => undefined);

    const doTyped = <T extends object, U extends object>(
      url: string,
      method: string,
      resultType: new () => U,
      requestType: new () => T,
      data: T,
      options?: FetchOptions
    ) => {
      if (data) {
        if (!options) {
          options = {};
        }
        options.body = JSON.stringify(jsonConvert.serialize(data, requestType));
      }
      return doText(url, method, options).then((response) =>
        jsonConvert.deserializeObject(EJSON.parse(response), resultType)
      );
    };

    const doTypedNoData = <T extends object>(
      url: string,
      method: string,
      resultType: new () => T,
      options?: FetchOptions
    ) =>
      doText(url, method, options).then((response) => jsonConvert.deserializeObject(EJSON.parse(response), resultType));

    const doTypedJSON = <T extends object, U extends object>(
      url: string,
      method: string,
      resultType: new () => U,
      requestType?: new () => T,
      data?: T,
      options?: FetchOptions
    ) => {
      if (data) {
        if (!options) {
          options = {};
        }
        options.body = JSON.stringify(jsonConvert.serialize(data, requestType));
      }
      return doJSON(url, method, options).then((response) => jsonConvert.deserializeObject(response, resultType));
    };

    const doTypedNoContent = <T extends object>(
      url: string,
      method: string,
      requestType?: new () => T,
      data?: T,
      options?: FetchOptions
    ) => {
      if (data) {
        if (!options) {
          options = {};
        }
        options.body = JSON.stringify(jsonConvert.serialize(data, requestType));
      }
      return doJSON(url, method, options);
    };

    const doTypedList = <T extends object>(
      url: string,
      method: string,
      resultType: new () => T,
      options?: FetchOptions
    ) => {
      return doText(url, method, options).then((response) =>
        Object.values(EJSON.parse(response) as { [s: string]: any }).map((val: any) => {
          const isPrimitive = val !== Object(val);
          if (isPrimitive) {
            return val as T;
          }
          return jsonConvert.deserializeObject(val, resultType);
        })
      );
    };

    return {
      _delete: (url: string, queryParams?: Record<string, any>) =>
        doJSON(url, 'DELETE', queryParams ? { queryParams } : undefined),
      _get: <T extends object>(
        url: string,
        resultType: new () => T,
        queryParams?: Record<string, any>,
        headers?: Record<string, string>,
        options?: FetchOptions
      ) =>
        doTypedNoData(url, 'GET', resultType, {
          headers,
          queryParams,
          ...options,
        }),
      _getConvert: <T>(
        converter: (response: any) => T,
        url: string,
        queryParams?: Record<string, any>,
        headers?: Record<string, string>,
        options?: FetchOptions
      ) =>
        doText(url, 'GET', {
          headers,
          queryParams,
          ...options,
        }).then((response) => converter(EJSON.parse(response))),
      _getUntyped: (
        url: string,
        queryParams?: Record<string, any>,
        headers?: Record<string, string>,
        options?: FetchOptions
      ) => doJSON(url, 'GET', { queryParams, headers, ...options }),
      _list: <T extends object>(
        url: string,
        resultType: new () => T,
        queryParams?: Record<string, any>,
        headers?: Record<string, string>,
        options?: FetchOptions
      ) =>
        doTypedList(url, 'GET', resultType, {
          headers,
          queryParams,
          ...options,
        }),
      _listConvert: <T>(
        url: string,
        converter: (response: any) => T,
        queryParams?: Record<string, any>,
        headers?: Record<string, string>,
        options?: FetchOptions
      ) =>
        doText(url, 'GET', {
          headers,
          queryParams,
          ...options,
        }).then((response) =>
          Object.values(EJSON.parse(response) as { [s: string]: any }).map((val: any) => converter(val))
        ),
      _patch: <T extends object>(url: string, requestType: new () => T, data: T) =>
        doTypedNoContent(url, 'PATCH', requestType, data),
      _patchTyped: <T extends object, U extends object>(
        url: string,
        resultType: new () => U,
        requestType: new () => T,
        data: T
      ) => doTyped(url, 'PATCH', resultType, requestType, data),
      _patchRaw: (url: string, options?: FetchOptions) => doJSON(url, 'PATCH', options),
      _post: <T extends object, U extends object>(
        url: string,
        resultType: new () => U,
        requestType: new () => T,
        data: T,
        queryParams?: Record<string, any>,
        options?: FetchOptions
      ) => doTyped(url, 'POST', resultType, requestType, data, { queryParams, ...options }),
      _postTypedJSON: <T extends object, U extends object>(
        url: string,
        resultType: new () => U,
        requestType?: new () => T,
        data?: T,
        queryParams?: Record<string, any>,
        options?: FetchOptions
      ) => doTypedJSON(url, 'POST', resultType, requestType, data, { queryParams, ...options }),
      _postConvert: <T, U>(
        url: string,
        data: U,
        resultConverter: (response: any) => T,
        requestConverter: (data: any) => U,
        queryParams?: Record<string, any>
      ) =>
        doText(url, 'POST', {
          body: JSON.stringify(requestConverter(data)),
          queryParams,
        }).then((response) => resultConverter(EJSON.parse(response))),
      _postNoContent: <T extends object>(
        url: string,
        requestType: new () => T,
        data: T,
        queryParams?: Record<string, any>
      ) => doTypedNoContent(url, 'POST', requestType, data, { queryParams }),
      _postNoData: <T extends object>(url: string, resultType: new () => T, queryParams?: Record<string, any>) =>
        doTypedNoData(url, 'POST', resultType, { queryParams }),
      _postRaw: (url: string, options?: FetchOptions) => doJSON(url, 'POST', options),
      _postUntyped: (
        url: string,
        body?: Record<string, any>,
        queryParams?: Record<string, any>,
        requiresLegacyAuth?: boolean
      ) =>
        doJSON(url, 'POST', {
          body: body ? JSON.stringify(body) : undefined,
          queryParams,
          requiresLegacyAuth,
        }),
      _put: <T extends object>(
        url: string,
        requestType: new () => T,
        data: T | undefined,
        queryParams?: Record<string, any>,
        options?: FetchOptions
      ) => doTypedNoContent(url, 'PUT', requestType, data, { queryParams, ...options }),
      _putConvertNoContent: <T>(
        url: string,
        data: T,
        requestConverter: (data: any) => T,
        queryParams?: Record<string, any>
      ) =>
        doNoContent(url, 'PUT', {
          body: JSON.stringify(requestConverter(data)),
          queryParams,
        }),
      _putRaw: (url: string, options?: FetchOptions) => doJSON(url, 'PUT', options),
    };
  }
}
