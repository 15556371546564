import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Badge from '@leafygreen-ui/badge';
import { GuideCue } from '@leafygreen-ui/guide-cue';
import Icon from '@leafygreen-ui/icon';
import { SideNav, SideNavGroup } from '@leafygreen-ui/side-nav';
import { Location } from 'history';
import { compose } from 'redux';

import EnvironmentBadge from 'baas-ui/common/components/environment-badge';
import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import { docLinks } from 'baas-ui/common/links';
import {
  graphQLDeprecationBadgeDismissed,
  hostingDeprecationBadgeDismissed,
  showAtlasDataAPIGuideCueKey,
  showAtlasTriggersGuideCueKey,
  thirdPartyServicesKey,
} from 'baas-ui/common/local-storage-keys';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import { AppProduct } from 'baas-ui/home/types';
import CopyText from 'baas-ui/nav/components/copy-text';
import { getAppState, getServiceState, getSettingsState } from 'baas-ui/selectors';
import { isSelfHostedOrAtlasMongoService } from 'baas-ui/services/registry';
import { featureSettings } from 'baas-ui/stitch_ui';
import { track } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import { rootUrl } from 'baas-ui/urls';
import {
  isEdgeFeatureAllowed,
  isGraphQLFeatureAllowed,
  isHostingFeaturedAllowed,
  isSyncFeatureAllowed,
} from 'baas-ui/util';
import { PartialApp, PartialServiceDesc } from 'admin-sdk';

import SideNavItemWithLink from './SideNavItemWithLink';

import './side-nav.less';
import 'baas-ui/settings/settings-page/settings-page.less';

export enum TestSelector {
  LinkedDataSources = 'linked-data-sources',
  ThirdPartyServices = 'third-party-services',
  Schemas = 'schemas',
  ComingSoonBadge = 'coming-soon-badge',
  DataServicesAPILink = 'data-services-data-api-link',
  DataServicesTriggersLink = 'data-services-triggers-link',
  DataServicesOverviewLink = 'data-services-overview-link',
  DataServicesDataAPIGuideCue = 'data-services-data-api-guide-cue',
  DataServicesTriggersGuideCue = 'data-services-triggers-guide-cue',
  EdgeNavLink = 'side-nav-edge',
  GraphQLBadge = 'graphql-badge',
  HostingBadge = 'hosting-badge',
  SyncNavLink = 'side-nav-sync',
  SDKsNavLink = 'side-nav-sdks',
}

interface ReduxStateProps {
  app: PartialApp;
  services: PartialServiceDesc[];
  atlasDataAPIUrl: string;
  atlasTriggersUrl: string;
  atlasOverviewUrl: string;
}

export interface RouterProps {
  location: Location;
}

export type Props = ReduxStateProps & RouterProps;

function isLocationForMongoDBService(location: Location, mongoServices: PartialServiceDesc[]) {
  const matchService = (service: PartialServiceDesc) => location.pathname.includes(service.id);
  return mongoServices.some(matchService);
}

export const SideNavComponent = ({
  app,
  services,
  atlasDataAPIUrl,
  atlasTriggersUrl,
  atlasOverviewUrl,
  location,
}: Props) => {
  const appRootUrl = rootUrl.groups().group(app.groupId).apps().app(app.id);
  const [thirdPartyServicesEnabled] = useLocalStorage(thirdPartyServicesKey(app.id), false);
  const [showAtlasDataAPIGuideCue, setShowAtlasDataAPIGuideCue] = useLocalStorage(
    showAtlasDataAPIGuideCueKey(app.id),
    true
  );
  const [showAtlasTriggersGuideCue, setShowAtlasTriggersGuideCue] = useLocalStorage(
    showAtlasTriggersGuideCueKey(app.id),
    true
  );
  const atlasDataAPILinkRef = useRef<HTMLDivElement | null>(null);
  const atlasTriggersLinkRef = useRef<HTMLDivElement | null>(null);
  const atlasOverviewLinkRef = useRef<HTMLDivElement | null>(null);

  const mongoServices = services.filter((svc) => isSelfHostedOrAtlasMongoService(svc.type));
  const gcmService = services.find((svc) => svc.type === 'gcm');

  const [isGQLBadgeDismissed, setIsGQLBadgeDismissed] = useLocalStorage(
    graphQLDeprecationBadgeDismissed(app.id),
    false
  );

  const [isHostingBadgeDismissed, setIsHostingBadgeDismissed] = useLocalStorage(
    hostingDeprecationBadgeDismissed(app.id),
    false
  );

  const decoupleAppServicesPhase1 = featureSettings.useFeatureSetting(FeatureFlag.DecoupleAppServicesPhase1);

  const isHostingAllowed = isHostingFeaturedAllowed();
  const isGraphQLAllowed = isGraphQLFeatureAllowed();
  const isGraphQLOrHostingAllowed =
    featureSettings.useFeatureSetting(FeatureFlag.GraphQLAllowed) ||
    featureSettings.useFeatureSetting(FeatureFlag.HostingAllowed);
  const showGQLBadge = !isGQLBadgeDismissed && isGraphQLOrHostingAllowed;
  const showHostingBadge = !isHostingBadgeDismissed && isGraphQLOrHostingAllowed;
  const isSyncAllowed = isSyncFeatureAllowed();
  const isEdgeAllowed = isEdgeFeatureAllowed();

  const sideNavClassName = 'side-nav';

  return (
    <SideNav data-cy={sideNavClassName} className={sideNavClassName} aria-label="side-nav">
      {decoupleAppServicesPhase1 && (
        <SideNavItemWithLink
          data-testid={TestSelector.DataServicesOverviewLink}
          active={false}
          to={atlasOverviewUrl}
          target="_self"
          glyph={<Icon glyph="ChevronLeft" className={`${sideNavClassName}-apps-icon`} />}
        >
          <span className={`${sideNavClassName}-atlas-link`} ref={atlasOverviewLinkRef}>
            Back to Data Services
          </span>
        </SideNavItemWithLink>
      )}
      {!decoupleAppServicesPhase1 && app.product === AppProduct.DataAPI && (
        <SideNavItemWithLink
          data-testid={TestSelector.DataServicesAPILink}
          active={false}
          to={atlasDataAPIUrl}
          target="_self"
          glyph={<Icon glyph="ChevronLeft" className={`${sideNavClassName}-apps-icon`} />}
        >
          <span className={`${sideNavClassName}-atlas-link`} ref={atlasDataAPILinkRef}>
            Go to Data API in Data Services
          </span>
          <GuideCue
            data-testid={TestSelector.DataServicesDataAPIGuideCue}
            title="Data API in Data Services"
            tooltipJustify="end"
            tooltipAlign="right"
            numberOfSteps={1}
            currentStep={0}
            open={showAtlasDataAPIGuideCue}
            refEl={atlasDataAPILinkRef}
            setOpen={setShowAtlasDataAPIGuideCue}
            popoverZIndex={ZIndex.Guide}
          >
            You can navigate directly to the Data API page in Data Services by clicking &quot;Go to Data API in Data
            Services&quot; on the left.
          </GuideCue>
        </SideNavItemWithLink>
      )}
      {!decoupleAppServicesPhase1 && app.product === AppProduct.Atlas && (
        <SideNavItemWithLink
          data-testid={TestSelector.DataServicesTriggersLink}
          active={false}
          to={atlasTriggersUrl}
          target="_self"
          glyph={<Icon glyph="ChevronLeft" className={`${sideNavClassName}-apps-icon`} />}
        >
          <span className={`${sideNavClassName}-atlas-link`} ref={atlasTriggersLinkRef}>
            Go to Triggers in Data Services
          </span>
          <GuideCue
            data-testid={TestSelector.DataServicesTriggersGuideCue}
            title="Triggers in Data Services"
            tooltipJustify="end"
            tooltipAlign="right"
            numberOfSteps={1}
            currentStep={0}
            open={showAtlasTriggersGuideCue}
            refEl={atlasTriggersLinkRef}
            setOpen={setShowAtlasTriggersGuideCue}
            popoverZIndex={ZIndex.Guide}
          >
            You can navigate directly to the Triggers page in Data Services by clicking &quot;Go to Triggers in Data
            Services&quot; on the left.
          </GuideCue>
        </SideNavItemWithLink>
      )}
      <SideNavItemWithLink
        active={false}
        to={rootUrl.groups().group(app.groupId).apps().list(true)}
        glyph={<Icon glyph="Apps" className={`${sideNavClassName}-apps-icon`} />}
      >
        Apps
      </SideNavItemWithLink>
      <div className={`${sideNavClassName}-app-group`}>
        <SideNavItemWithLink dataTarget="Dashboard" to={appRootUrl.dashboard()} glyph={<Icon glyph="Home" />}>
          <div className={`${sideNavClassName}-app-name`}>
            <CopyText
              display={app.name}
              copyText={app.clientAppId}
              tooltipDisplay="Copy App ID"
              onIconClick={() => track('SIDE_NAV.APP_ID_COPIED')}
            />
          </div>
        </SideNavItemWithLink>
        <SideNavItemWithLink
          className={`${sideNavClassName}-environment-container`}
          active={false}
          to={appRootUrl.deploy().environment()}
          dataTarget="Environment"
        >
          <EnvironmentBadge className={`${sideNavClassName}-environment-badge`} environment={app.environment} />
        </SideNavItemWithLink>
      </div>
      <SideNavGroup header="Data Access" glyph={<Icon glyph="Cloud" />}>
        {!!mongoServices.length && (
          <SideNavItemWithLink
            to={appRootUrl.rules().list()}
            active={
              location.pathname.includes('/rules') &&
              !location.pathname.includes('/services') &&
              !location.pathname.includes('/push')
            }
          >
            Rules
          </SideNavItemWithLink>
        )}
        <SideNavItemWithLink
          data-test-selector={TestSelector.Schemas}
          to={appRootUrl.schemas().root()}
          active={location.pathname.includes(appRootUrl.schemas().root())}
        >
          Schema
        </SideNavItemWithLink>
        <SideNavItemWithLink
          to={appRootUrl.auth().list()}
          active={location.pathname.includes('/users') || location.pathname.includes('/userSettings')}
        >
          App Users
        </SideNavItemWithLink>
        <SideNavItemWithLink
          to={appRootUrl.auth().providers().list()}
          active={location.pathname.includes('/providers')}
        >
          Authentication
        </SideNavItemWithLink>
      </SideNavGroup>
      <SideNavGroup header="Build" glyph={<Icon glyph="Laptop" />}>
        {isSyncAllowed && (
          <>
            <SideNavItemWithLink data-testid={TestSelector.SDKsNavLink} to={appRootUrl.sdks().list()}>
              Atlas Device SDK
            </SideNavItemWithLink>
            <SideNavItemWithLink data-testid={TestSelector.SyncNavLink} to={appRootUrl.sync().root()}>
              Device Sync
            </SideNavItemWithLink>
          </>
        )}
        {isEdgeAllowed && (
          <SideNavItemWithLink data-testid={TestSelector.EdgeNavLink} to={appRootUrl.edge().root()}>
            Edge Server
          </SideNavItemWithLink>
        )}
        {/* TODO(BAAS-31888): remove this */}
        {isGraphQLAllowed && (
          <SideNavItemWithLink
            dataTarget="GraphQL"
            to={appRootUrl.graphql().root()}
            onClick={() => setIsGQLBadgeDismissed(true)}
          >
            GraphQL &nbsp;
            {showGQLBadge && (
              <Badge variant="yellow" data-testid={TestSelector.GraphQLBadge}>
                1
              </Badge>
            )}
          </SideNavItemWithLink>
        )}
        <SideNavItemWithLink
          to={appRootUrl.functions().list()}
          active={
            location.pathname.includes(appRootUrl.functions().list()) ||
            location.pathname.includes(appRootUrl.dependencies().list())
          }
        >
          Functions
        </SideNavItemWithLink>
        <SideNavItemWithLink to={appRootUrl.triggers().list()}>Triggers</SideNavItemWithLink>
        <SideNavItemWithLink to={appRootUrl.endpoints().list()}>HTTPS Endpoints</SideNavItemWithLink>
        {thirdPartyServicesEnabled && (
          <SideNavItemWithLink
            to={appRootUrl.services().list()}
            active={
              location.pathname.includes(appRootUrl.services().list()) &&
              !isLocationForMongoDBService(location, mongoServices)
            }
            data-test-selector={TestSelector.ThirdPartyServices}
            dataTarget="3rd Party Services"
          >
            3rd Party Services
          </SideNavItemWithLink>
        )}
        <SideNavItemWithLink
          to={appRootUrl.values().list()}
          active={
            location.pathname.includes(appRootUrl.values().list()) ||
            location.pathname.includes(appRootUrl.secrets().list()) ||
            location.pathname.includes(appRootUrl.environmentValues().list())
          }
        >
          Values
        </SideNavItemWithLink>
      </SideNavGroup>
      <SideNavGroup header="Manage" glyph={<Icon glyph="Settings" />}>
        <SideNavItemWithLink
          to={appRootUrl.clusters().list()}
          active={
            !location.pathname.includes('/rules') &&
            (location.pathname.includes(appRootUrl.clusters().list()) ||
              isLocationForMongoDBService(location, mongoServices))
          }
          data-test-selector={TestSelector.LinkedDataSources}
          dataTarget="Linked Data Sources"
        >
          Linked Data Sources
        </SideNavItemWithLink>
        <SideNavItemWithLink to={appRootUrl.deploy().list()}>Deployment</SideNavItemWithLink>
        {/* TODO(BAAS-31889): remove this */}
        {isHostingAllowed && (
          <SideNavItemWithLink
            dataTarget="Hosting"
            to={appRootUrl.hosting().list()}
            onClick={() => setIsHostingBadgeDismissed(true)}
          >
            Hosting &nbsp;
            {showHostingBadge && (
              <Badge variant="yellow" data-testid={TestSelector.HostingBadge}>
                1
              </Badge>
            )}
          </SideNavItemWithLink>
        )}
        <SideNavItemWithLink to={appRootUrl.logs().list()}>Logs</SideNavItemWithLink>
        <SideNavItemWithLink to={appRootUrl.settings().list()}>App Settings</SideNavItemWithLink>
        {thirdPartyServicesEnabled && (
          <SideNavItemWithLink
            to={gcmService ? appRootUrl.push().list() : appRootUrl.push().config()}
            active={location.pathname.includes(appRootUrl.push().list())}
          >
            Push Notifications
          </SideNavItemWithLink>
        )}
      </SideNavGroup>
      <SideNavGroup header="Help" glyph={<Icon glyph="Support" />}>
        <SideNavItemWithLink to={docLinks.General.AppServices}>Documentation</SideNavItemWithLink>
        <SideNavItemWithLink to={docLinks.General.Tutorials}>Tutorials</SideNavItemWithLink>
      </SideNavGroup>
    </SideNav>
  );
};

const mapStateToProps = (state: RootState) => {
  const { app } = getAppState(state);
  const { svcsById } = getServiceState(state);
  const { cloudUIBaseUrl } = getSettingsState(state);

  return {
    app,
    services: Object.values(svcsById),
    atlasDataAPIUrl: `${cloudUIBaseUrl}/v2/${app.groupId}#dataAPI`,
    atlasTriggersUrl: `${cloudUIBaseUrl}/v2/${app.groupId}#triggers`,
    atlasOverviewUrl: `${cloudUIBaseUrl}/v2/${app.groupId}#/overview`,
  };
};

export default compose(withRouter, connect(mapStateToProps))(SideNavComponent);
